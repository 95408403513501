import { React, useState, useRef } from "react"
import LazyLoad from "react-lazyload"
/* import { IoCloseOutline } from "react-icons/io5"
import { BiLoaderAlt } from "react-icons/bi" */
import PropTypes from "prop-types"
import background from "../images/background_blur.webp"
import logo from "../images/logo.svg"

const Header = ({ siteTitle }) => {
  //Video modal const
  const videoTimeinSec = 71
  const [modal, setModal] = useState(false)
  const [videoLoading, setVideoLoading] = useState(true)

  /*   const videoStart = () => {
    CTA("Video started")
    openModal()
    //Start the video time measurement
    handleReset()
    handleStart()
  } */

  /*   const videoClose = () => {
    //Stop the video time measurement
    handlePause()
    if (typeof window !== "undefined") {
      //From 10% left it is marked as complete
      if (timer >= videoTimeinSec - videoTimeinSec * 0.1) {
        window.analytics.track("Video complete")
      } else {
        window.analytics.track("Video stopped", {
          watched: Math.round((timer * 100) / videoTimeinSec).toString() + "%",
        })
      }
    }
    openModal()
  } */

  //Track call to action (Calendly Button)
  /*   const CTA = (name, buttonNumber) => {
    if (typeof window !== "undefined") {
      window.analytics.track(name, {
        button: buttonNumber,
      })
    }
  }

  const openModal = () => {
    setModal(!modal)
  }

  const spinner = () => {
    setVideoLoading(!videoLoading)
  } */

  //Timer to count the video time
  const [timer, setTimer] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const countRef = useRef(null)

  const handleStart = () => {
    // start button logic here
    setIsActive(true)
    setIsPaused(true)
    countRef.current = setInterval(() => {
      setTimer(timer => timer + 1)
    }, 1000)
  }

  const handlePause = () => {
    // Pause button logic here
    clearInterval(countRef.current)
    setIsPaused(false)
  }

  const handleReset = () => {
    // Reset button logic here
    clearInterval(countRef.current)
    setIsActive(false)
    setIsPaused(false)
    console.log(isActive)
    console.log(isPaused)
    setTimer(0)
  }

  return (
    <header>
      <section
        className="bg-top bg-cover bg-no-repeat py-6"
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div className="mx-auto">
          <nav className="flex justify-center px-8 pb-5 w-2/3 md:w-1/3 xl:w-full mx-auto">
            <div className="block">
              <LazyLoad>
                <img
                  src={logo}
                  alt="Logo"
                  width={150}
                  height={49}
                  loading="lazy"
                />
              </LazyLoad>
            </div>
          </nav>

          <div className="text-center">
            <div className="w-11/12 md:w-2/3 lg:1/2 xl:w-7/12 xl2:w-5/12 mx-auto mb-6">
              <h1 className="mb-4 text-white leading-tight text-4xl lg:text-5xl xl:text-5xl">
                <span>
                  Wir sorgen für eine schnelle und effiziente Umsetzung des BSI IT-Grundschutzes
                </span>
                {/* <span className="text-red-400 font-black animate-pulse-slow">
                  robusten Hacker-Schutz!
                </span> */}
              </h1>
              <p className="mx-auto xl:w-10/12 text-base text-semibold text-white leading-tight">
                In nur 30 Minuten zeigen wir Dir, wie verwundbar Dein
                Unternehmen gegen Cyberbedrohungen wirklich ist und ob Du
                bereits unbemerkt Datenverluste hattest. Hol Dir dazu einen
                klaren, einfachen Fahrplan für die Umsetzung des
                BSI-Grundschutzes, um Dein Business effektiv zu sichern.
              </p>
            </div>
            <div>
              <div className="flex flex-wrap gap-4 justify-center text-center px-3 sm:px-10 md:px-20">
                {/*Modal for Video https://codesandbox.io/s/nkwxb?file=/src/App.js:481-490 */}
                {/* <button onClick={videoStart} className="btn-white sm:max-w-xs">
                  Was ist Ransomware?
                </button>
                {modal ? (
                  <section className="fixed top-0 left-0 w-screen h-screen z-50 bg-black/50">
                    <div className="modal__align">
                      <div className="modal__content" modal={modal.toString()}>
                        <IoCloseOutline
                          className="modal__close mx-auto"
                          arial-label="Close modal"
                          onClick={videoClose}
                        />
                        <div className="modal__video-align">
                          {videoLoading ? (
                            <div className="modal__spinner">
                              <BiLoaderAlt className="modal__spinner-style" />
                            </div>
                          ) : null}
                          <div className="modalContainer">
                            <iframe
                              onLoad={spinner}
                              loading="lazy"
                              title="Wistia Player"
                              className="responsive-iframe"
                              allowtransparency="true"
                              frameBorder="0"
                              scrolling="no"
                              allow="autoplay; fullscreen"
                              src="https://fast.wistia.net/embed/iframe/huu3vysi35?seo=false&videoFoam=true"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                ) : null} */}
                <a
                  className="w-full sm:max-w-xs"
                  href="https://outlook.office365.com/book/BSIBeratung@aroundsec.de/"
                  target="_blank"
                >
                  <button className="btn-red">
                    Jetzt BSI-Beratung sichern
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
